export const PaymentColors = {
  SUCCESS: 'var(--payment-success)',
  SUCCESS_SHADOW: 'var(--payment-success-shadow)',
  SUCCESS_ADDITIONAL_SHADOW: 'var(--payment-success-additional-shadow)',
  FAIL: 'var(--payment-fail)',
  FAIL_SHADOW: 'var(--payment-fail-shadow)',
  FAIL_ADDITIONAL_SHADOW: 'var(--payment-fail-additional-shadow)',
  PAYMENT_GRADIENT_LIGHTEN: 'var(--payment-gradient-lighten)',
  PAYMENT_GRADIENT_LIGHTEN_REVERSED: 'var(--payment-gradient-lighten-reversed)',
  PAYMENT_GRADIENT: 'var(--payment-gradient)',
  WAIT: 'var(--payment-wait)',
  WAIT_SHADOW: 'var(--payment-wait-shadow)',
  WAIT_ADDITIONAL_SHADOW: 'var(--payment-wait-additional-shadow)',
};
